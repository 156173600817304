import React from "react"

import Layout from "../components/layout"
import AsideLayout from '../components/AsideLayout'
import SEO from "../components/seo"
import Splash from '../components/splash'

const AboutPage = () => (
  <Layout>
    <SEO title="about" />
    <AsideLayout
      aside={
        <div>
          <img src="/pup.jpg" className="rounded shadow m-tb" />
          <img src="/koda.jpg" className="rounded shadow m-tb" />
          <img src="/thor.jpg" className="rounded shadow m-tb" />
        </div>
      }
    >
      <section>
        <h1>About us</h1>
        <article>
          <p>We are a small German Shepherd Dog breeder based near Rylstone in the NSW Central Tablelands. Our focus is on producing the healthiest, strongest
          German Shepherd Dogs possible. We absolutely aim for quality over quantity and function over form. We will <em>never</em> breed for aesthetics alone (although our dogs are gorgeous!) and we always give our bitches at least 12 months between litters to ensure they get the break they need and stay in prime condition.</p>
          <p>Our dogs are all loved as part of our family and get to enjoy exploring our 100 acre bush block as well as time inside. They especially love a swim in the dam during hot summer days!</p>
        </article>
      </section>
      <section>
        <h1>About our dogs</h1>
        <article>
          <h2>The breed</h2>
          <p>German Shepherd Dogs are an extemely loyal, protective and loving breed that usually forms strong bonds with their family members. Originally bred as working dogs they can be a bundle of energy and do thrive on exercise and mental stimulation. Like other intelligent and active breeds they can become destructive when bored, so they're best suited to active families with the time and energy to invest into their dog. In recent decades "show lines" have become popular. They are generally less active and larger, often with a larger head and shoulders sloping down to a relatively smaller rear (often referred to as "roach back" due to the exagerated curve in the spine as it drops off to the hind quarter). We breed a more traditional, straight backed German Shepherd Dog that we believe is truer to the breed and has potentially less joint problems.</p>
        </article>
        <article>
          <h2>Diet</h2>
          <p>All our dogs, adults and puppies alike, are fed a healthy, natural, raw food diet which includes:</p>
          <ul>
            <li>Beef</li>
            <li>Kangaroo</li>
            <li>Duck</li>
            <li>Chicken</li>
            <li>Whole oily fish (salmon, sardines, pilchards, mackerel)</li>
            <li>Offal (heart, liver, kidney etc)</li>
            <li>Fresh free range eggs</li>
            <li>Jersey milk and/or goat milk</li>
            <li>Grated vegetables</li>
            <li>Natural supplement</li>
            <li>Apple cider vinegar</li>
          </ul>
          <p>Our dogs thrive on this diet. Their coats are smooth and shiny and they never get the "doggy" smell and rarely ever need a bath (a swim in the dam is about it!). If our puppies do get especially smelly (you know how dogs just love to roll in the stinkiest things possible!) they are only ever washed using natural goat milk soap. Our dogs are also all flea-free.</p>
        </article>
        <article>
          <h2>Training</h2>
          <p>We believe in keeping our pups with their mum for a minimum of 10 weeks before they go to their new loving homes.
             This allows mum (and dad) more time to teach them doggy etiquette. While we try to give them the best start possible
              getting the most from your new friend will require you to continue on with good training.</p>
        </article>
      </section>
    </AsideLayout>
  </Layout>
)

export default AboutPage
