import React from 'react'

import * as style from '../styles/theme/asideLayout.module.scss'

const AsideLayout = (props) => {
  
  return (
    <div className={ style.container } >
      <main className={ style.main }>
        { props.children }
      </main>
      <aside className={ style.aside }>
        { props.aside }
      </aside>
    </div>
  )
}

export default AsideLayout
