import React from 'react'
import * as style from '../styles/theme/splash.module.scss'

const Splash = ({ image, children }) => (
  <div className={ style.splash } style={{ backgroundImage: `url(${image})` }}>
    { children }
  </div>
)

export default Splash
